@import '~ngx-toastr/toastr';

@import 'common/variables';

/** Import autoload files */
@import 'autoload/bootstrap';
@import 'autoload/fonts';

@import '~swiper/swiper-bundle';

@import 'vendor/fontawesome';

@import 'common/global';
@import 'common/typography';

@import 'mixins/buttons';
@import 'mixins/forms';

@import 'components/buttons';
@import 'components/cards';
@import 'components/forms';
@import 'components/lists';
@import 'components/tables';
