@use "sass:math";

.table {
  .table-header {
    padding: calc( $grid-gutter-width/2) 0;
    border-bottom: 2px solid rgba($black, 0.4);
    display: flex;
    align-items: center;

    .table-header-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: $font-weight-bold;
    }
  }
  .table-rows {
    display: flex;
    align-items: center;
    padding: math.div($grid-gutter-width, 1.5) 0;
    border-bottom: 1px solid rgba($black, 0.4);
  }
}