.list-group {
  .list-group-item-action {
    padding-right: 2rem;

    &:before {
      content: '>';
      position: absolute;
      right: 1rem;
    }
  }

  .custom-control {
    .list-group-action-link {
      position: absolute;
      right: 1rem;
      border: none;
      background: none;
    }
  }
}