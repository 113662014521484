/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-calendar-alt:before { content: fa-content($fa-var-calendar-alt); }
.#{$fa-css-prefix}-check:before { content: fa-content($fa-var-check); }
.#{$fa-css-prefix}-clock:before { content: fa-content($fa-var-clock); }
.#{$fa-css-prefix}-globe:before { content: fa-content($fa-var-globe); }
.#{$fa-css-prefix}-map-marker:before { content: fa-content($fa-var-map-marker); }
.#{$fa-css-prefix}-times:before { content: fa-content($fa-var-times); }
.#{$fa-css-prefix}-user:before { content: fa-content($fa-var-user); }
