// `sage preset` installed this file automatically.
// Running `sage preset` again could result in automatic deletion of this file.
// Because of this, we do not recommend editing this file.
// @import "~bootstrap/scss/bootstrap";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";



html, body {
  height: 100%;
}

$responsive_text_columns : (
    1: (sm: (cols: 1, gutter: 0rem), md: (cols: 1, gutter: 0rem), lg: (cols: 1, gutter: 0rem), xl: (cols: 1, gutter: 0rem)),
    2: (sm: (cols: 1, gutter: 0rem), md: (cols: 1, gutter: 0rem), lg: (cols: 2, gutter: 1.2rem), xl: (cols: 2, gutter: 1.5rem)),
    3: (sm: (cols: 1, gutter: 0rem), md: (cols: 2, gutter: 1rem), lg: (cols: 3, gutter: 1.2rem), xl: (cols: 3, gutter: 1.5rem)),
    4: (sm: (cols: 1, gutter: 0rem), md: (cols: 2, gutter: 1rem), lg: (cols: 4, gutter: 1.2rem), xl: (cols: 4, gutter: 1.5rem)),
);

// Responsive Text columns
@each $num_columns, $apply in $responsive_text_columns {
  .text-columns-#{$num_columns} {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-gap: 0em;
      -moz-column-gap: 0em;
      column-gap: 0em;
  }
  @each $scale in ("sm", "md", "lg", "xl")
  {
      @include media-breakpoint-up($scale) {
          .text-columns-#{$num_columns} {
              -webkit-column-count: map-get(map-get($apply , $scale ), "cols");
              -moz-column-count: map-get(map-get($apply , $scale ), "cols");
              column-count: map-get(map-get($apply , $scale ), "cols");
              -webkit-column-gap: map-get(map-get($apply , $scale ), "gutter");
              -moz-column-gap: map-get(map-get($apply , $scale ), "gutter");
              column-gap: map-get(map-get($apply , $scale ), "gutter");
          }
      }
  }
}