// Variables
// --------------------------

@use "sass:math";

$fa-font-path:         "../webfonts" !default;
$fa-font-size-base:    16px !default;
$fa-font-display:      block !default;
$fa-css-prefix:        fa !default;
$fa-version:           "5.15.4" !default;
$fa-border-color:      #eee !default;
$fa-inverse:           #fff !default;
$fa-li-width:          2em !default;
$fa-fw-width:          math.div(20em, 16);
$fa-primary-opacity:   1 !default;
$fa-secondary-opacity: .4 !default;

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

$fa-var-calendar-alt: \f073;
$fa-var-check: \f00c;
$fa-var-clock: \f017;
$fa-var-globe: \f0ac;
$fa-var-map-marker: \f041;
$fa-var-times: \f00d;
$fa-var-user: \f007;
