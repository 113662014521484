@import '~bootstrap/scss/functions';
@import '../common/variables';
@import '~bootstrap/scss/mixins';
@import '../mixins/forms';

$navbar-height: 72px;

.layout {
  padding-top: $navbar-height;
}

a {
  cursor: pointer;
  text-decoration: underline;
}