/* always add "font-display: swap;" to each @font-face*/
@font-face {
  font-family: 'Typold';
  src: url('../../assets/fonts/Typold-Book.eot');
  src: url('../../assets/fonts/Typold-Book.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Typold-Book.woff2') format('woff2'),
      url('../../assets/fonts/Typold-Book.woff') format('woff'),
      url('../../assets/fonts/Typold-Book.ttf') format('truetype'),
      url('../../assets/fonts/Typold-Book.svg#Typold-Book') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Typold';
  src: url('../../assets/fonts/Typold-BookItalic.eot');
  src: url('../../assets/fonts/Typold-BookItalic.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Typold-BookItalic.woff2') format('woff2'),
      url('../../assets/fonts/Typold-BookItalic.woff') format('woff'),
      url('../../assets/fonts/Typold-BookItalic.ttf') format('truetype'),
      url('../../assets/fonts/Typold-BookItalic.svg#Typold-BookItalic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Typold';
  src: url('../../assets/fonts/Typold-Medium.eot');
  src: url('../../assets/fonts/Typold-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Typold-Medium.woff2') format('woff2'),
      url('../../assets/fonts/Typold-Medium.woff') format('woff'),
      url('../../assets/fonts/Typold-Medium.ttf') format('truetype'),
      url('../../assets/fonts/Typold-Medium.svg#Typold-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Typold';
  src: url('../../assets/fonts/Typold-MediumItalic.eot');
  src: url('../../assets/fonts/Typold-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Typold-MediumItalic.woff2') format('woff2'),
      url('../../assets/fonts/Typold-MediumItalic.woff') format('woff'),
      url('../../assets/fonts/Typold-MediumItalic.ttf') format('truetype'),
      url('../../assets/fonts/Typold-MediumItalic.svg#Typold-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Typold';
  src: url('../../assets/fonts/Typold-Bold.eot');
  src: url('../../assets/fonts/Typold-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Typold-Bold.woff2') format('woff2'),
      url('../../assets/fonts/Typold-Bold.woff') format('woff'),
      url('../../assets/fonts/Typold-Bold.ttf') format('truetype'),
      url('../../assets/fonts/Typold-Bold.svg#Typold-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Typold';
  src: url('../../assets/fonts/Typold-BoldItalic.eot');
  src: url('../../assets/fonts/Typold-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Typold-BoldItalic.woff2') format('woff2'),
      url('../../assets/fonts/Typold-BoldItalic.woff') format('woff'),
      url('../../assets/fonts/Typold-BoldItalic.ttf') format('truetype'),
      url('../../assets/fonts/Typold-BoldItalic.svg#Typold-BoldItalic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
