.card {
  &.event-information {
    border: none;
    margin-bottom: calc( $grid-gutter-width/2);
    background-color: $primary;
    padding: calc( $grid-gutter-width/2);

    i {
      color: $secondary;
    }
  }
}