h1 {
  &.title {
    line-height: $display-line-height;
    font-size: $display4-size;
    text-transform: uppercase;
    margin-bottom: 2rem;
    @include media-breakpoint-up(sm) {
      font-size: $display3-size;
      letter-spacing: -1px;
    }
    @include media-breakpoint-up(md) {
      font-size: $display2-size;
      letter-spacing: -2px;
    }
  }
}