@mixin button-line-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  border-color: transparent;

  &::after{
    position: relative;
    bottom: 0;
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: $color;
    border-radius: 1px;
    transition: $transition-base;
  }

  @include hover {
    &::after{
      bottom: 2px;
    }
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    &::after{
      bottom: 2px;
    }

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows and $btn-active-box-shadow != none {
        box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5);
      } @else {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }
}


@mixin button-variant-ea($color, $value) {
  position: relative;
  background-color: transparent;
  border-color: $black;
  @if $value == secondary {
    color: $white;
  } @else {
    color: $black;
  }


  &::before{
    display: block;
    content: '';
    position: absolute;
    left: calc(-1 * $spacer/2);
    top: calc(-1 * $spacer/2);
    width: 100%;
    height: 100%;
    background: $color;
    z-index: -1;
    transition: $transition-base;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: transparent;
    border-color: $black;

    @if $value == secondary {
      color: $white;
    } @else {
      color: $black;
    }

    &::before{
      left: 0;
      top: 0;
    }
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background: transparent;
  }

  &.disabled,
  &:disabled {
    border-color: rgba($black, 0.7);
    background-color: transparent;
    opacity: 1;

    &::before {
      opacity: 0.4;
    }
  }


}