.btn{
  @if $enable-rounded-buttons {
    border-radius: $btn-padding-y + (($btn-line-height*0.5)*$btn-font-size) + 0.063rem; //last value is btn-border-width
    &-sm{
      border-radius: $btn-padding-y-sm + (($btn-line-height-sm*0.5)*$btn-font-size-sm) + 0.063rem; //last value is btn-border-width
    }
    &-lg{
      border-radius: $btn-padding-y-lg + (($btn-line-height-lg*0.5)*$btn-font-size-lg) + 0.063rem; //last value is btn-border-width;
    }
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &-lg{
    text-transform: uppercase;
  }

  &-other{

  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant-ea($value, $color);
  }
}